
import { defineComponent, onMounted } from "vue";
import Card2 from "@/components/cards/Card2.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "campaigns",
  components: {
    Card2,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Campaigns", ["Pages", "Profile"]);
    });
  },
});
